<template>
  <v-tooltip
    :disabled="!active"
    :value="active"
    :nudge-right="50"
    max-width="400"
    color="primary"
    content-class="px-6 py-4"
    right
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-group
        v-model="isOpenGroup"
        :prepend-icon="item.icon"
        no-action
        class="mb-2 rounded-md"
        :class="[active ? 'border-4 border-solid border-primary' : '']"
        append-icon="mdi-menu-down"
        v-bind="attrs"
        v-on="on"
      >
        <template v-slot:activator>
          <v-list-item-content
            class="v-list-item__icon--text"
            style="margin-left: 0 !important"
          >
            <v-list-item-title
              class="text-lg text-left"
              v-text="item.title"
            ></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.children"
          :key="child.title"
          :href="href"
          :rel="href && href !== '#' ? 'noopener' : undefined"
          :target="href && href !== '#' ? '_blank' : undefined"
          :to="child.to"
        >
          <template>
            <v-icon class="pr-2">{{ child.icon }}</v-icon>
          </template>
          <v-list-item-content>
            <v-list-item-title class="text-lg" v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </template>
    <v-icon
      style="position: absolute"
      color="primary"
      class="-left-12 top-1/3 animate-bounce duration-150"
      large
    >
      mdi-undo
    </v-icon>
    <span v-html="tooltipContent" class="text-lg text-color-4" />
    <v-btn
      color="gray"
      class="float-right clear-right mt-2 pointer-events-auto"
      @click.stop="onClickNext"
    >
      Đã hiểu
    </v-btn>
  </v-tooltip>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";

export default {
  name: "ItemGroup",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        title: undefined,
        to: undefined,
        children: [],
      }),
    },
    tooltipContent: {
      type: String,
      default: "Tooltip",
    },
    enableTooltip: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    nextStep: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      isOpenGroup: false,
    };
  },

  computed: {
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },

  watch: {
    active() {
      this.isOpenGroup = this.active;
    },
  },

  methods: {
    onClickNext() {
      this.isOpenGroup = false;
      setTimeout(() => {
        this.$store.commit("setStepGuide", this.nextStep);
      }, 300);
    },
  },
};
</script>

<style scoped>
.v-tooltip__content.menuable__content__active {
  opacity: 1;
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
.v-tooltip__content.menuable__content__active
  opacity: 1

.v-list-item
  font-size: 18px

.v-list-item__icon
  margin: 12px 20px 12px 0px !important

.v-list-item--active
  color: black !important
  background-color: #c8c8c8 !important
  font-weight: bold

.v-list-item--link:before
  background-color: transparent !important

.v-list-item--highlighted
  background-color: rgba($color: #000000, $alpha: 0.35)

.v-navigation-drawer__border
  width: 0 !important

.v-list-group__header.v-list-item--active
  color: black !important
  background-color: transparent !important
  font-weight: bold

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
